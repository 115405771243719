<template>
  <div v-if="employeeDetails" style="padding:20px;">
    <div class="row">
      <div class="col-12">
        <button class="btn btn-sm btn-success float-right" html-type="submit" @click="saveEmployeeDetails()">
          <em class="icon ni ni-save-fill mr-2" /> Save
        </button>
      </div>
    </div>
      <a-form-model ref="user" :model="employeeDetails">
        <div class="row">
          <div class="col-5">
            <label class="form-label float-right">Gender</label>
          </div>
          <div class="col-4">
            <a-select class="invisibox" v-model="employeeDetails.genderId" placeholder="Gender" >
                <a-select-option v-for="g in genders" :key="g.id">
                  {{ g.text }}
                </a-select-option>
              </a-select>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <label class="form-label float-right">Date Of Birth</label>
          </div>
          <div class="col-4">
            <a-date-picker class="invisibox" v-model="employeeDetails.dateOfBirth" placeholder="Date Of Birth" format="DD/MM/YYYY" />
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <label class="form-label float-right">Driving License Number</label>
          </div>
          <div class="col-4">
            <a-input class="invisibox" v-model="employeeDetails.drivingLicenseNumber" placeholder="Driving License Number" />
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <label class="form-label float-right">Phone</label>
          </div>
          <div class="col-4">
            <a-input class="invisibox" v-model="employeeDetails.telephoneNumber" placeholder="Phone Number" />
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <label class="form-label float-right">Line 1</label>
          </div>
          <div class="col-4">
            <a-input class="invisibox" v-model="employeeDetails.addressLineOne" placeholder="Line One" />
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <label class="form-label float-right">Line 2</label>
          </div>
          <div class="col-4">
            <a-input class="invisibox" v-model="employeeDetails.addressLineTwo" placeholder="Line Two" />
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <label class="form-label float-right">Town / City</label>
          </div>
          <div class="col-4">
            <a-input class="invisibox" v-model="employeeDetails.townOrCity" placeholder="Town Or City" />
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <label class="form-label float-right">County / State</label>
          </div>
          <div class="col-4">
            <a-input class="invisibox" v-model="employeeDetails.countyOrState" placeholder="County Or State" />
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <label class="form-label float-right">Country</label>
          </div>
          <div class="col-4">
           <a-input class="invisibox" v-model="employeeDetails.country" placeholder="Country" />
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <label class="form-label float-right">Postcode</label>
          </div>
          <div class="col-4">
             <a-input class="invisibox" v-model="employeeDetails.postCode" placeholder="Postcode" />
          </div>
        </div>
      </a-form-model>
      <button class="btn btn-sm btn-success float-right" html-type="submit" @click="saveEmployeeDetails()">
          <em class="icon ni ni-save-fill mr-2" /> Save
      </button>
  </div>
</template>

<script>
export default {
    
props: {
    userId: {
      type: String
    },
    default: null
},
data () {
    return {
        employeeDetails: null,
        genders: null
    }
},
created() {
    this.getEmployeeDetails()
    this.getLists()
},
watch: {
   userId: function() {
    this.getEmployeeDetails()
   }
},
methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.updateUser()
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    updateUser  () {
      this.$http.post('/users/Update_User/', this.user)
        .then(() => {
          this.$emit('userUpdated')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    getEmployeeDetails () {
        this.$http.get('/Users/get_EmployeeDetails/' + this.userId)
        .then((response) => {
        this.employeeDetails = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    },
    saveEmployeeDetails () {
      this.employeeDetails.userId = this.userId
      if (this.userId != null) {
        this.$http.post('/users/Update_Employee_Details/', this.employeeDetails)
        .then(() => {
          this.$message.success('Details Updated')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
      } else {
        this.$http.post('/users/Add_Employee_Details/', this.employeeDetails)
        .then(() => {
          this.$message.success('Details Added')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
      }
    },

    getLists() {
      this.$http.get('/lists/Get_List/genders/')
        .then((response) => {
        this.genders = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    }
}
}
</script>

<style scoped>
.form-label {
  margin-top: 9px
}
</style>