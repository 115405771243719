<template>
    <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm pb-2">
            <div class="nk-block-between">
                <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">Employees</h3>
                    <div class="nk-block-des text-soft">
                        <p v-if="employeeView === 1">You have a total {{ employees.length }} active employees.</p>
                        <p v-if="employeeView === 2">You have a total {{ employeesVacated.length }} vacated / previous employees.</p>
                        <a-radio-group v-model="employeeView">
                            <a-radio :value="1">Active</a-radio>
                            <a-radio :value="2">Vacated / Previous</a-radio>
                        </a-radio-group>
                    </div>
                </div>
                <!-- .nk-block-head-content -->
                <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                        <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                        <div class="toggle-expand-content" data-content="pageMenu">
                            <ul class="nk-block-tools g-3">
                                <li>
                                    <download-csv :data="csvData">
                                        <button class="btn btn-white btn-outline-light">
                                            <i class="icon ni ni-download-cloud" style="margin-right:5px;"></i>
                                            Export
                                        </button>
                                    </download-csv>
                                    <!-- <a href="#" class="btn btn-white btn-outline-light" @click="exportUsers()"><em class="icon ni ni-download-cloud"></em><span>Export</span></a> -->
                                </li>
                                <li>
                                    <button class="btn btn-dark text-light" v-on:click="addEmployee()"><em class="icon ni ni-plus mr-1"></em>Add Employee</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- .toggle-wrap -->
                </div>
                <!-- .nk-block-head-content -->
            </div>
            <!-- .nk-block-between -->
        </div>
        <!-- .nk-block-head -->
        <div class="nk-block">
            <div class="card p-2 card-stretch">
                <div class="card-inner-group">
                    <div class="card-inner p-0">
                        <div class="nk-tb-list nk-tb-ulist is-compact">
                            <!-- ACTIVE TABLE -->
                            <vue-good-table
                                ref="my-table"
                                v-if="employees && employeeView === 1"
                                :columns="columns"
                                :rows="employees"
                                styleClass="vgt-table condensed"
                                :search-options="{
                                    enabled: true,
                                    placeholder: 'Search Employees'
                                }"
                                :sort-options="{ enabled: true }"
                                :pagination-options="{
                                    enabled: true,
                                    mode: 'records',
                                    perPage: 50,
                                    position: 'bottom',
                                    perPageDropdown: [50, 100, 150],
                                    dropdownAllowAll: false,
                                    setCurrentPage: 1,
                                    nextLabel: 'Next',
                                    prevLabel: 'Prev',
                                    rowsPerPageLabel: 'Rows per page',
                                    ofLabel: 'of',
                                    pageLabel: 'page', // for 'pages' mode
                                    allLabel: 'All'
                                }"
                            >
                                <template slot="table-row" slot-scope="props">
                                    <div v-if="props.column.field == 'roles'">
                                        <span v-for="r in props.row.roles" v-bind:key="r.id" class="badge mr-2 badge-primary">{{ r.name }}</span>
                                    </div>
                                    <div v-else-if="props.column.field === 'csvSelect'">
                                        <a-checkbox v-model="props.row.selected" @change="test(props.row)"></a-checkbox>
                                    </div>
                                    <div v-else @click="showProfile(props)">
                                        <span>{{ props.formattedRow[props.column.field] }}</span>
                                    </div>
                                </template>
                            </vue-good-table>
                            <!-- VACATED TABLE -->
                            <vue-good-table
                                v-if="employees && employeeView === 2"
                                :columns="columns"
                                :rows="employeesVacated"
                                @on-cell-click="showProfile"
                                styleClass="vgt-table condensed"
                                :search-options="{
                                    enabled: true,
                                    placeholder: 'Search Employees'
                                }"
                                :sort-options="{ enabled: true }"
                                :pagination-options="{
                                    enabled: true,
                                    mode: 'records',
                                    perPage: 50,
                                    position: 'bottom',
                                    perPageDropdown: [50, 100, 150],
                                    dropdownAllowAll: false,
                                    setCurrentPage: 1,
                                    nextLabel: 'Next',
                                    prevLabel: 'Prev',
                                    rowsPerPageLabel: 'Rows per page',
                                    ofLabel: 'of',
                                    pageLabel: 'page', // for 'pages' mode
                                    allLabel: 'All'
                                }"
                            >
                                <template slot="table-row" slot-scope="props">
                                    <div v-if="props.column.field == 'roles'">
                                        <span v-for="r in props.row.roles" v-bind:key="r.id" class="badge mr-2 badge-primary">{{ r.name }}</span>
                                    </div>
                                    <div v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </div>
                                </template>
                            </vue-good-table>
                        </div>
                        <!-- .nk-tb-list -->
                    </div>
                    <!-- .card-inner -->
                </div>
                <!-- .card-inner-group -->
            </div>
            <!-- .card -->
        </div>
        <!-- .nk-block -->
        <a-drawer title="Employee" :width="700" placement="right" :closable="true" @close="closeEmployee()" :visible="showEmployee">
            <employee v-if="selectedEmployee" @updated="closeEmployee" :selectedEmployee="selectedEmployee"></employee>
        </a-drawer>
        <!-- end employee  -->

        <a-modal title="Add Employee" :placement="'bottom'" height="60%" width="60%" :closable="true" :dialog-style="{ top: '35px' }" v-model="showAddEmployeeWizard">
            <template slot="footer">
                <a-button @click="hideEmployeeWizard()">Close</a-button>
            </template>
            <addemployeewizard @saved="savedEmployee"></addemployeewizard>
        </a-modal>
        <!-- end drawers -->
    </div>
</template>
<script>
import employee from '@/components/people/employees/employee'
import addemployeewizard from '@/components/people/employees/addemployeewizard'
export default {
    components: {
        employee,
        addemployeewizard
    },
    computed: {
        csvData() {
            let _csvData = this.selectedEmployees.map(obj => ({
                firstName: obj.firstName,
                lastName: obj.lastName,
                phoneNumber: obj.phoneNumber,
                email: obj.email,
                addressLineOne: obj.addressLineOne,
                addressLineTwo: obj.addressLineTwo,
                town: obj.townOrCity,
                county: obj.countyOrState,
                postcode: obj.postCode
            }))

            return _csvData
        }
    },
    data: function() {
        return {
            showAddEmployeeWizard: false,
            showEmployee: false,
            selectedEmployee: null,
            employeeView: 1,
            employees: [],
            employeesVacated: [],
            selectedEmployees: [],
            columns: [
                { label: '', field: 'csvSelect' },
                { label: 'Employee First Name', field: 'firstName' },
                { label: 'Employee Last Name', field: 'lastName' },
                { label: 'Employee Phone Number', field: 'phoneNumber' },
                { label: 'Email', field: 'email' },
                { label: 'Job Title', field: 'jobRole' }
                //{ label: 'Actions', field: 'actions', tdClass: 'text-center', thClass: 'text-center', width: 200 },
            ]
        }
    },
    methods: {
        test(test) {
            this.selectedEmployees.push(test)

            //console.log(this.selectedEmployees)
        },
        getView() {
            this.$http
                .get('/ViewAccess/employees')
                .then(() => {})
                .catch(() => {})
        },
        hideEmployeeWizard() {
            this.employees = []
            this.getEmployees()
            this.showAddEmployeeWizard = false
        },
        addEmployee() {
            this.showAddEmployeeWizard = true
            // this.selectedEmployee = {id: null}
            // this.showEmployee = true
        },
        editEmployee(employee) {
            this.selectedEmployee = employee
            this.showEmployee = true
        },
        deleteUser(employee) {
            this.$http
                .post('/Users/Delete_Employee/', employee)
                .then(() => {
                    this.removeDeletedUser(employee)
                    this.$message.success('Employee Successfully Removed')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
        },
        getEmployees() {
            this.$http
                .get('/users/get_Employees')
                .then(response => {
                    this.employees = response.data
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            this.$http
                .get('/users/get_VacatedEmployees')
                .then(response => {
                    this.employeesVacated = response.data
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
        },
        openEmployee() {
            this.showEmployee = true
        },
        closeEmployee() {
            this.showEmployee = false
        },
        showProfile(params) {
            //alert(JSON.stringify(params.row.id))
            this.$router.push({ name: 'employee', query: { userId: params.row.id } })
        },
        savedEmployee() {
            this.employees = []
            this.getEmployees()
            this.showAddEmployeeWizard = false
        }
    },
    created() {
        this.getView()
        this.getEmployees()
    }
}
</script>
<style></style>
