<template>
  <div style="padding:20px;">
      <div v-if="!user" class="text-center">
          Settings become available once the user has been saved.
      </div>
      <div v-if="user">
          <table class="table table-striped">
          <tr>
            <td>Send User Password Reset</td>
            <td>
                <a-button @click="sendPasswordReset()" icon="mail">
                    Send
                </a-button>
            </td>
          </tr>
          <tr>
            <td>Allow Login</td>
            <td>
                <a-switch @change="enableDisableLogin()" v-model="user.allowLogin" >
                  <a-icon slot="checkedChildren" type="check" />
                  <a-icon slot="unCheckedChildren" type="close" />
                </a-switch>
            </td>
          </tr>
        </table>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        user: {
            type:Object
        }
    },
    data () {
        return {
        }
    },
    methods: {
    sendPasswordReset () {
        alert('This needs doing!!!!')
    },
    enableDisableLogin () {
      this.$http.post('/users/EnableDisable_Login/', this.user)
        .then(() => {
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
},
}
</script>

<style>

</style>