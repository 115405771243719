<template>
  <div>
    <a-steps initial="0" :current="current">
        <a-step key="1"  title="Personal Details">
            <em slot="icon" class="icon ni ni-user-list-fill"></em>
        </a-step>
        <a-step key="2" title="Job Details">
            <em slot="icon" class="icon ni ni-building"></em>
        </a-step>
        <a-step key="3" title="Holiday / Hours">
            <em slot="icon" class="icon ni ni-calendar-fill"></em>
        </a-step>
        <a-step key="4" title="User Settings">>
            <em  slot="icon" class="icon ni ni-account-setting-fill"></em>
        </a-step>
        <a-step key="5" title="Holiday Exceptions">
            <em slot=icon class="icon ni ni-calendar"></em>
        </a-step>
    </a-steps>
    <div class="row p-4">
        <div class="col-md-12">
            <div v-if="current == 1" class="card card-bordered">
                <div class="card-inner">
                    <div v-if="employee.employeeDetails">
                        <a-form-model ref="user" :model="employee.employeeDetails">
                            <div class="row">
                                <div class="col-5">
                                    <label class="form-label float-right">First Name</label>
                                </div>
                                <div class="col-4">
                                    <a-input class="invisibox" v-model="employee.user.firstName" :class="{'is-error' : $v.employee.user.firstName.$invalid }" placeholder="First Name" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5">
                                    <label class="form-label float-right">Last Name</label>
                                </div>
                                <div class="col-4">
                                    <a-input class="invisibox" v-model="employee.user.lastName" :class="{'is-error' : $v.employee.user.lastName.$invalid }" placeholder="Last Name" />  
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5">
                                    <label class="form-label float-right">Email</label>
                                </div>
                                <div class="col-4">
                                    <a-input class="invisibox" v-model="employee.user.email" :class="{'is-error' : $v.employee.user.email.$invalid }" placeholder="Email Address" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5">
                                    <label class="form-label float-right">Gender</label>
                                </div>
                                <div class="col-4">
                                    <a-select class="invisibox" v-model="employee.employeeDetails.genderId" placeholder="Select Gender" >
                                        <a-select-option v-for="g in genders" :key="g.id">
                                        {{ g.text }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Date Of Birth</label>
                            </div>
                            <div class="col-4">
                                <a-date-picker class="invisibox" v-model="employee.employeeDetails.dateOfBirth" placeholder="Date Of Birth" format="DD/MM/YYYY" />
                            </div>
                            </div>

                            <div class="row">
                                <div class="col-5">
                                    <label class="form-label float-right">NI / SSN Number</label>
                                </div>
                                <div class="col-4">
                                    <a-input class="invisibox" v-model="employee.employeeJobDetails.niNumberOrSSN" placeholder="NI / SSN Number" />
                                </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Driving License Number</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.drivingLicenseNumber" placeholder="Driving License Number" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Phone</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.telephoneNumber" placeholder="Phone Number" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Line 1</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.addressLineOne" placeholder="Line One" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Line 2</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.addressLineTwo" placeholder="Line Two" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Town / City</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.townOrCity" placeholder="Town Or City" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">County / State</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.countyOrState" placeholder="County Or State" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Country</label>
                            </div>
                            <div class="col-4">
                            <a-input class="invisibox" v-model="employee.employeeDetails.country" placeholder="Country" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Postcode</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.postCode" placeholder="Postcode" />
                            </div>
                            </div>
                        </a-form-model>
                        <!-- <a-button class="btn-success" block html-type="submit" @click="saveEmployeeDetails()">
                            <em class="icon ni ni-plus" style="fontSize:12px; padding:5px; padding-top:6px" /> Save Details
                        </a-button> -->
                    </div>
                </div>
            </div>
            <div v-if="current == 2" class="card card-bordered">
                <div class="card-inner">
                    <div v-if="employee.employeeJobDetails">
                        <a-form-model ref="user" :model="employee.employeeJobDetails">
                    <div class="row">
                        <div class="col-5">
                            <label class="form-label float-right">Location</label>
                        </div>
                        <div class="col-4">
                            <a-input class="invisibox" v-model="employee.employeeJobDetails.location" placeholder="Location" />
                        </div>
                    </div>                   

                    <div class="row">
                        <div class="col-5">
                            <label class="form-label float-right">Department</label>
                        </div>
                        <div class="col-4">
                            <a-select class="invisibox" v-model="employee.employeeJobDetails.departmentId" placeholder="Select Department">
                                <a-select-option key="1">
                                    TO DO WHEN THEY ARE BUILT
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>     
                                                    
                    <div class="row">
                        <div class="col-5">
                            <label class="form-label float-right">Job Role</label>
                        </div>
                        <div class="col-4">
                            <a-input class="invisibox" v-model="employee.employeeJobDetails.jobRole" placeholder="Job Role" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                            <label class="form-label float-right">Reports To</label>
                        </div>
                        <div class="col-4">
                            <a-select class="invisibox" show-search v-model="employee.employeeJobDetails.reportsToId" option-filter-prop="children" :filter-option="filterOption" placeholder="Select Supervisor">
                                <a-select-option v-for="u in users" :key="u.id">
                                    {{ u.firstName + ' ' + u.lastName}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                            <label class="form-label float-right">Start Date</label>
                        </div>
                        <div class="col-4">
                            <a-date-picker class="invisibox" format="DD/MM/YYYY" v-model="employee.employeeJobDetails.startDate" :class="{'is-error' : $v.employee.employeeJobDetails.startDate.$invalid }" placeholder="Start Date" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                            <label class="form-label float-right">Holiday Year</label>
                        </div>
                        <div class="col-4">
                        <a-select class="invisibox" v-model="employee.employeeJobDetails.holidayYearId" :class="{'is-error' : $v.employee.employeeJobDetails.holidayYearId.$invalid }" placeholder="Select Holiday Year">
                            <a-select-option v-for="h in holidayYears" :key="h.id">
                                {{ h.text }}
                            </a-select-option>
                        </a-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                            <label class="form-label float-right">Work Pattern</label>
                        </div>
                        <div class="col-4">
                        <a-select class="invisibox" v-model="employee.employeeJobDetails.workPatternId" :class="{'is-error' : $v.employee.employeeJobDetails.workPatternId.$invalid }" placeholder="Select Work Pattern">
                            <a-select-option v-for="w in workPatterns" :key="w.id">
                                {{ w.text }}
                            </a-select-option>
                        </a-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                            <label class="form-label float-right">Salary Period</label>
                        </div>
                        <div class="col-4">
                        <a-select class="invisibox" v-model="employee.employeeJobDetails.salaryPeriodId" placeholder="Select Salary Period">
                            <a-select-option v-for="s in salaryPeriods" :key="s.id">
                                {{ s.text }}
                            </a-select-option>
                        </a-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                            <label class="form-label float-right">Salary Amount</label>
                        </div>
                        <div class="col-4">
                            <a-input class="invisibox" v-model="employee.employeeJobDetails.salaryAmount" placeholder="0.00" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                            <label class="form-label float-right">Salary Currency</label>
                        </div>
                        <div class="col-4">
                            <a-select class="invisibox" v-model="employee.employeeJobDetails.salaryCurrencyId" placeholder="Select Currency">
                                <a-select-option v-for="c in currencies" :key="c.id">
                                    {{ c.text }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>                                                        
                    </a-form-model>
                    </div>
                </div>
            </div>
            <div v-if="current == 3" class="card card-bordered">
                <div class="card-inner">
                    <div v-if="employee.employeeAnnualLeaveAllowance">
                        <a-form-model ref="annualLeave" :model="employee.employeeAnnualLeaveAllowance" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="durationMeasurementId">Annual Leave Measurement</label>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select v-model="employee.employeeAnnualLeaveAllowance.durationMeasurementId" :class="{'is-error' : $v.employee.employeeAnnualLeaveAllowance.durationMeasurementId.$invalid }" type="text" class="invisibox" id="durationMeasurementId" placeholder="Select Annual Leave Measurement">
                                                <a-select-option v-for="a in durationMeasurements" :key="a.id">
                                                    {{ a.text }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- <a-form-model-item
                                prop="durationMeasurementId"
                                ref="durationMeasurementId"
                                :rules="{
                                required: false,
                                message: 'Please enter the users full name',
                                trigger: 'blur',
                                }"
                                label="Location">
                                <a-input v-model="annualLeave.durationMeasurementId" placeholder="John Smith" />
                            </a-form-model-item>                             -->
                                    
                            <div class="row g-3 align-center">
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="contractedHours">Contracted Hours</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                            <a-input placeholder="-" v-model="employee.employeeAnnualLeaveAllowance.contractedHours" :class="{'is-error' : $v.employee.employeeAnnualLeaveAllowance.contractedHours.$invalid }" type="text" class="invisibox" id="contractedHours"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>                                     
                            <div class="row g-3 align-center">
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="fullTimeHours">Full-Time Hours</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                            <a-input placeholder="-" v-model="employee.employeeAnnualLeaveAllowance.fullTimeHours" :class="{'is-error' : $v.employee.employeeAnnualLeaveAllowance.fullTimeHours.$invalid }" type="text" class="invisibox" id="fullTimeHours"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>                              
                            <div class="row g-3 align-center">
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="fullTimeEntitlement">Full-Time Entitlement</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                            <a-input placeholder="-" class="invisibox" v-model="employee.employeeAnnualLeaveAllowance.fullTimeEntitlement" :class="{'is-error' : $v.employee.employeeAnnualLeaveAllowance.fullTimeEntitlement.$invalid }" id="fullTimeEntitlement"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>                                   
                            <div class="row g-3 align-center">
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="entitlementIncludesPublicHolidays">Entitlement Includes Public Holidays</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                            <a-checkbox v-model="employee.employeeAnnualLeaveAllowance.entitlementIncludesPublicHolidays" id="entitlementIncludesPublicHolidays"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>                                
                            <div class="row g-3 align-center">
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="allowExceedAllowance">Allow Exceed Allowance</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                            <a-checkbox v-model="employee.employeeAnnualLeaveAllowance.allowExceedAllowance" id="allowExceedAllowance"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>  
                        </a-form-model>    
                    </div>
                </div>
            </div>
            <div v-if="current == 4" class="card-bordered">
                <div class="card-inner">
                    <div v-if="employee.user">
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Current User</label>
                            </div>
                            <div class="col-4">
                                <a-select class="invisibox" v-model="employee.user.id" style="width: 100%" placeholder="Select User">
                                    <a-select-option v-for="u in notEmployeeUsers" :key="u.id">
                                    {{ u.firstName + ' ' + u.lastName }}
                                    </a-select-option>
                                </a-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right" style="margin-top: 0px">New User</label>
                            </div>
                            <div class="col-4">
                                <a-switch @change="newUser()" v-model="user.new">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                            </div>
                        </div> 
                    </div>
                    <div v-if="employee.user.new == true">
                        <hr class="preview-hr">
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right" style="margin-top: 0px">Allow Login</label>
                            </div>
                            <div class="col-4">
                                <a-switch @change="enableDisableLogin()" v-model="employee.user.allowLogin">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Primary Role</label>
                            </div>
                            <div class="col-4">
                                <a-select class="invisibox" v-model="employee.user.primaryRoleId" style="width: 100%" placeholder="Select Role">
                                    <a-select-option v-for="r in userRoles" :key="r.id">
                                        {{ r.name }}
                                    </a-select-option>
                                </a-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">First Name</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.user.firstName" placeholder="First Name" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Last Name</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.user.lastName" placeholder="Last Name" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Email Address</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" @change="emailChanged" v-model="employee.user.email" placeholder="Email" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right" style="margin-top: 0px">Send Welcome Email</label>
                            </div>
                            <div class="col-4">
                                <a-checkbox  :disabled="!employee.user.email" v-model="employee.user.sendUserWelcomeEmail">Email The User Login Details</a-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="current == 5" class="card-bordered">
                <div class="card-inner">
                    <div v-if="createdUser">
                        <div class="row">
                            <div class="col-12">
                                <h6>Holiday Exceptions</h6>
                                <label class="form-label">Is it a National Holiday or a Working Day?</label>
                                <a-radio-group v-model="isHoliday" style="padding-left: 20px;">
                                    <a-radio :value="1">
                                        National Holiday
                                    </a-radio>
                                    <a-radio :value="2">
                                        Working Day
                                    </a-radio>
                                </a-radio-group>
                            </div>
                        </div>
                        <div class="row" v-if="isHoliday === 1">
                            <div class="col-12">
                                <label class="form-label">National Holiday</label>
                                    <a-select style="width:100%;" v-model="holidayException.nationalHolidayId">
                                        <a-select-option v-for="n in nationalHolidays" :key="n.nationalHolidayId" v-on:click="setNationalHoliday(n)">
                                            {{ n.name }}
                                        </a-select-option>
                                    </a-select>
                            </div>
                        </div>
                        <div class="row" v-if="isHoliday === 2">
                            <div class="col-12">
                                <label class="form-label">Date</label>
                                <a-date-picker style="width:100%;" v-model="holidayException.date"/>
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-12">
                                <a-button class="btn btn-sm btn-success float-right" @click="saveHolidayException()"><em class="icon ni ni-save mr-1"></em> Save Exception</a-button>
                            </div>
                        </div>
                        <hr class="preview-hr">
                        <div class="row">
                            <div class="col-12">
                                <h6>Selected Exceptions</h6>
                                <vue-good-table v-if="holidayExceptions"
                                    :columns="columns"
                                    :rows="holidayExceptions"
                                    styleClass="vgt-table condensed"
                                >
                                <template slot="table-row" slot-scope="props">
                                    <div  v-if="props.column.field == 'calendarDateId'" >
                                        {{$moment(props.row.calendarDateId).format("LL")}}
                                    </div>
                                    <div v-else-if="props.column.field == 'action'">
                                        <a-dropdown>
                                        <a><em class="icon ni ni-more-h" style="fontSize: 20px"></em></a>
                                        <a-menu slot="overlay">
                                            <a-menu-item style="color: red;" @click="deleteHolidayException(props)"><em class="icon ni ni-cross-circle"/> Delete</a-menu-item>
                                        </a-menu>
                                        </a-dropdown>
                                    </div>
                                </template>
                                </vue-good-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-4">
        <div class="col-md-12">
            <a-button v-if="current >= 2 && current != 5" style="margin-left: 8px;"  @click="current -= 1">
                Previous
            </a-button>
            <a-button v-if="current < 4" type="primary" class="float-right btn-dark" style="color: #fff;" @click="current += 1">
                Next
            </a-button>
            <a-button
                v-if="current == 4"
                type="primary"
                class="float-right btn-success" style="color: #fff;"
                @click="saveEmployee()"
            >
            <em class="icon ni ni-save mr-1"></em>
                Save
            </a-button>
        </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    validations: {
        employee: {
            user: {
                firstName: {
                    required
                },
                lastName: {
                    required
                },
                email: {
                    required
                }
            },
            employeeJobDetails: {
                startDate: {
                    required
                },
                holidayYearId: {
                    required
                },
                workPatternId: {
                    required
                }
            },
            employeeAnnualLeaveAllowance: {
                durationMeasurementId: {
                    required
                },
                contractedHours: {
                    required
                },
                fullTimeHours: {
                    required
                },
                fullTimeEntitlement: {
                    required
                }
            },
        }
    },
    data () {
        return {
            current: 1,
            employeeDetails: {},
            genders: {},
            employeeJobDetails: {},
            annualLeave: {},
            user: {new: true},
            users: null,
            notEmployeeUsers: null,
            durationMeasurements: null,
            workPatterns: null,
            currencies: null,
            salaryPeriods: null,
            userRoles: null,
            holidayYears: null,
            employee: {
                employeeDetails: {},
                employeeJobDetails: {},
                employeeAnnualLeaveAllowance: {},
                holidayException: {},
                user: {
                    new: true,
                    allowLogin: false,
                    primaryRoleId: 'BB5C89B3-CFEC-49F7-A0D1-A05F0F77EFDE'
                },
            },
            columns: [
                { label: 'Date', field: 'calendarDateId'},
                { label: 'National Holiday Name', field: 'nationalHolidayName'},
                { label: 'Actions', field: 'action', tdClass: 'text-center', thClass: 'text-center', width: 200 },
            ],
            filterOption: '',
            holidayExceptions: null,
            holidayException: {},
            nationalHolidays: null,
            isHoliday: 1,
            createdUser: {},
            needsHolidayExceptions: false,
        }
    },
    created() {
        this.getLists()
    },
    methods: {
        getLists() {
            this.$http.get('/lists/Get_List/genders/')
            .then((response) => {
                this.genders = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/users/get_UserNotEmployees')
            .then((response) => {
                this.notEmployeeUsers = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/durationMeasurements')
            .then((response) => {
                this.durationMeasurements = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/workPatterns')
            .then((response) => {
                this.workPatterns = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/currencies')
            .then((response) => {
                this.currencies = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/salaryPeriods')
            .then((response) => {
                this.salaryPeriods = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/users/Get_Users')
            .then((response) => {
                this.users = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/holidayYears')
            .then((response) => {
                this.holidayYears = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_User_Roles')
            .then((response) => {
                this.userRoles = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/settings/Get_Tenant_EnabledNationalHolidays')
            .then((response) => {
                this.nationalHolidays = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        emailChanged() {
            if (this.user.email === null || this.user.email === '') {
                this.user.sendUserWelcomeEmail = false
            }
        },
        saveEmployee(){
            if (this.$v.employee.$invalid) {
                this.$message.error('Please check for any form errors.')
            } else {
                this.$http.post('/users/Add_Employee_Wizard/', this.employee)
                .then((response) => {
                    this.createdUser = response.data
                    this.$message.success('Added')
                    this.getHolidayExceptions()
                    this.current += 1
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
            }
        },
        enableDisableLogin(){
            if (this.employee.user.allowLogin == false) {
                this.employee.user.allowLogin = true
            } else {
                this.employee.user.allowLogin = false
            }
        },
        newUser(){
            if (this.employee.user.id != null) {
                this.$message.error('You have selected a user')
                this.employee.user.new = false
            } else {
                if (this.employee.user.new == false) {
                    this.employee.user.new = true
                } else {
                    this.employee.user.new = false
                }
            }
        },
        getHolidayExceptions() {
            this.$http.get('/people/Get_Holiday_Exceptions/' + this.createdUser.user.id)
            .then((response) => {
                this.holidayExceptions = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        setNationalHoliday(n){
            this.holidayException.calendarDateId = n.calendarDateId
        },
        saveHolidayException(){
            this.holidayException.userId = this.createdUser.user.id
            if (this.isHoliday === 1) {
                this.holidayException.isHoliday = true
            } else {
                this.holidayException.isHoliday = false
            }

            this.$http.post('/people/Add_Holiday_Exception', this.holidayException)
            .then((response) => {
                this.holidayExceptions = response.data
                this.getHolidayExceptions()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        deleteHolidayException(props){
            this.$http.post('/people/Delete_Holiday_Exception', props.row)
            .then(() => {
                this.$message.success('Holiday Exception Deleted')
                this.getHolidayExceptions()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    },

}
</script>

<style scoped>
.form-label {
    margin-top: 6px;
}
</style>