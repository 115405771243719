<template>
  <div v-if="selectedEmployee">
     <a-tabs default-active-key="1" >
      <a-tab-pane key="1" tab="Employee Details">
        <employeedetails :userId="selectedEmployee.id" @updated="hasUpdated"></employeedetails>
      </a-tab-pane>
      <!-- <a-tab-pane key="2" tab="Permissions" force-render>
        <userroles :userId="selectedEmployee.id" @updated="hasUpdated"></userroles>
      </a-tab-pane> -->
      <a-tab-pane key="3" tab="Job Details">
         <employeejobdetails :userId="selectedEmployee.id" @updated="hasUpdated"></employeejobdetails>
      </a-tab-pane>
      <a-tab-pane key="4" tab="Leave / Holidays">
         <employeeannualleave :userId="selectedEmployee.id" @updated="hasUpdated"></employeeannualleave>
      </a-tab-pane>
      <a-tab-pane key="5" tab="Settings">
         <employeesettings :user="selectedEmployee" @updated="hasUpdated"></employeesettings>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import employeedetails from '@/components/people/employees/employeedetails'
//import userroles from '@/components/users/userroles'
import employeesettings from '@/components/people/employees/employeesettings'
import employeejobdetails from '@/components/people/employees/employeejobdetails'
import employeeannualleave from '@/components/people/employees/employeeannualleave'
// import store from '@/store '
export default {
  props: {
    selectedEmployee: Object,
  },
    components: {employeedetails, employeesettings, employeejobdetails, employeeannualleave },
  //watch: {
  //  user: function(newVal) { // watch for change
  //    if (newVal.id != null) {
  //      getUser()
  //    }
  //  },
  //},
  data: function () {
    return {
      displayMe: true,
    }
  },
  name: 'user',
  methods: {  
      hasUpdated (){
          this.$emit('updated')
      },
  },
}
</script>
